<div class="modal__content">
    <div class="confirmation-popup-header">
        <i class="fas {{headerIcon}}"></i>
        <span> {{question}}</span>
    </div>
    <div *ngIf="text" class="confirmation-popup-text">
        <span>{{text}}</span>
    </div>
</div>
<div class="modal__footer">
    <button type="reset" class="btn btn-link" (click)="answer(false);">{{cancelButtonText}}</button>
    <button type="button" class="btn btn-primary"
    [ngClass]="{'btn-primary' : !danger, 'btn-danger': danger}"
     (click)="answer(true)">{{confirmButtonText}}</button>
</div>
