<div class="row content-box-container">
    <div class="col-lg-6 content-panel left-content">
        <i class="fa-solid fa-3x loading-icon bounce {{currentAnimationIcon}}"></i>
        <p class="heading-text">Template Updates Processing</p>
        <p class="body-text">Your financial institution has made changes to the spreading template used by this borrower. These updates may take a few minutes to complete.</p>
    </div>
    <div class="col-lg-6 content-panel right-content">
        <div *ngFor="let listItem of instructionListItems" class="list-item-container">
            <div class="list-item-header-row" [style.color]="listItem.headingColor">
                <i class="fa-solid {{listItem.icon}}"></i>
                <p>{{listItem.headingText}}</p>
            </div>
            <p class="list-item-body-content">{{listItem.bodyText}}</p>
        </div>
    </div>
</div>
