import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable, of, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {BorrowerService} from "@services/borrower.service";


@Injectable({
  providedIn: 'root'
})
export class CheckForReSpreadResolver implements Resolve<any> {
  constructor(
    private _borrowerService: BorrowerService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this._borrowerService.checkForReSpreadBorrowerLockByStatementUuid(route.params.statement_uuid)
      .pipe(
        map(response => {
          return response['borrowerIsLocked']
        }),
        catchError(err => {
          console.error("Failed to load borrower data. Unable to determine if a re-spread lock exists for this borrower:", err)
          return of(false)
        })
      )
  }
}
