import { map, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ClientSearch } from '../models/client-search';
import { Company, CompanyPatch } from '../models/company';
import { PeriodOperation } from '../models/period-operation';
import { FiscalYearEndChange } from '../models/fiscal-year-end-change';
import { ApiService } from './api.service';
import { SharedDataService } from './shared-data.service';
import { AutoUnsubscribe } from '../decorators/auto-unsubscribe';

@AutoUnsubscribe('subsArr$')
@Injectable()
export class BorrowerService {
  filters: any;
  company: Company;
  companyPatch: CompanyPatch;
  subsArr$: Subscription[] = [];

  constructor(private apiService: ApiService,
    private _sharedDataService: SharedDataService,
  ) {
    this.subsArr$.push(this._sharedDataService.company$.pipe(filter(company => company !== null)).subscribe(company => {
      this.company = company;
    }));
  }

  getCompanyById(id): Observable<any> {
    return this.apiService.send('Get', '/api/companies/' + id).pipe(
      map(data => <Company>data.response.objects.pop()));
  }

  getCompanyByUuid(uuid, params): Observable<any> {
    return this.apiService.send('Post', '/api/companies/all', {
      filter: {'uuid_eq': uuid}
    }).pipe(
      map(data => <Company>data.response.objects.pop()));
  }

  searchCompanies(page?: number, limit?: number, clientSearch?: ClientSearch, sort?: any) {
    if (page || limit || clientSearch || sort) {
      this.setFilters(page, limit, clientSearch, sort);
    }
    return this.apiService.send('Post', '/api/companies/all', this.filters);
  }

  searchBorrowersByName(name: string) {
    return this.apiService.send('Post', '/api/companies/all', {
      filter: {'name_icontains': name}
    });
  }

  setFilters(page: number, limit: number, clientSearch: ClientSearch, sort: any) {
    sort = sort.field + '.' + (sort.dir ? 'asc' : 'desc')
    this.filters = {
      filter: {
          'name_icontains': clientSearch.search,
          'external_id_icontains': clientSearch.customerIDSearch,
          'portfolio_id_eq': clientSearch.portfolio,
          'sic_code_eq': clientSearch.sicCode,
          'region_eq': clientSearch.region,
        },
      page: page,
      limit: limit,
      sort: sort,
      ignoreLoading: false
    };

    // Removes all filters not being used for this query
    for (const item in this.filters.filter) {
        if (item && (this.filters.filter[item] === '' || this.filters.filter[item] === ' ')) {
          delete this.filters.filter[item];
        }
    }

    return this.filters;
  }

  companyUpdate(company: CompanyPatch, id: number): Observable<any> {
    return this.apiService.send('Patch', '/api/companies/' + id, company).pipe(
      map(data => <Company>data.response.objects.pop()));
  }

  companyAdd(company: Company): Observable<any> {
    return this.apiService.send('Post', '/api/companies', company).pipe(
      map(data => <Company>data.response.objects.pop()));
  }

  saveRequiredValue(periodOperation: PeriodOperation) {
    return this.apiService.send(periodOperation.periodOperationId ? 'Put' : 'Post', '/api/period-operations' +
      (periodOperation.periodOperationId ? ('/' + periodOperation.id) : ''), periodOperation).pipe(
      map(data => data.response.objects));
  }

  getSetting(setting): boolean {
    if (!this.company || !('settings' in this.company)) {
      return false;
    }

    if (setting in this.company.settings) {
      return this.company.settings[setting];
    } else {
      return false;
    }
  }

  setSetting(setting, value, companyObject?: Company): void {
    const company = companyObject ? companyObject : this.company
    if (!company || !('settings' in company)) {
      console.error('Failed to update company setting')
      return;
    }

    if (setting in company.settings) {
      company.settings[setting] = value;
      const companySettings = {
        settings: company.settings
      }
      this.subsArr$.push(this.companyUpdate(companySettings, company.id).subscribe());
    }
  }

  getCompanyFYEChanges(company: Company): Observable<any> {
    return this.apiService.send('Post', `/api/fiscal-year-end-changes/all`, {
        filter: { company_id_eq: company.id }
      }).pipe(map(data => {
      return data.response.objects as Array<FiscalYearEndChange>;
    }));
  }

  addFiscalYearEndChange(company: Company, fyeDate: string): Observable<any> {
    return this.apiService.send('Post', `/api/fiscal-year-end-changes`, {
        company_id: company.id,
        fiscal_year_end_change: fyeDate
      }).pipe(map(data => {
      return data.response.objects[0] as FiscalYearEndChange;
    }));
  }

  deleteFiscalYearEndChange(fyeChangeId: number): Observable<any> {
    return this.apiService.send('Delete', `/api/fiscal-year-end-changes/${fyeChangeId}`);
  }

  displayFriendlyName(name: string): string {
    return name;
  }

  getAllRegions(): Observable<any> {
    return this.apiService.send('Get', '/api/companies/regions').pipe(map(data => data.response.objects));
  }

  checkForReSpreadBorrowerLockByStatementUuid(statementUuid): Observable<any> {
    return this.apiService.send('Post', '/api/companies/check-for-re-spread-borrower-lock', {
      statement_uuid: statementUuid
    }).pipe(map(data => data.response.objects[0]));
  }

  resolveReSpreadWarningModal(companyId: number): Observable<any> {
    return this.apiService.send('Patch', '/api/companies/' + companyId, {
      'showReSpreadIncludedIncompleteStatementsWarningModal': false
    })
  }
}
