import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

interface InstructionListItem {
  icon: string;
  headingColor: string;
  headingText: string;
  bodyText: string;
}


const animationIconChangeIntervalInMS: number = 3000;

@Component({
  selector: 'app-template-re-spread-loading-view',
  templateUrl: './template-re-spread-loading-view.component.html',
  styleUrls: ['./template-re-spread-loading-view.component.scss']
})

export class TemplateReSpreadLoadingViewComponent implements OnInit, OnDestroy {
  instructionListItems: InstructionListItem[] = [
    {
      icon: 'fa-info-circle',
      headingColor: '#464646',
      headingText: 'What changes have been made?',
      bodyText: 'These changes could include the addition of new line items or calculations, changes to existing line items or calculations, removal of existing line items or calculations, or reordering of line items.'
    },
    {
      icon: 'fa-check-circle',
      headingColor: '#1D6E2A',
      headingText: 'What can I do?',
      bodyText: 'During this process you can still upload and spread new documents for this borrower, and any spreads in progress can be worked on up to Data Categorization. Borrowers using different templates remain unaffected.'
    },
    {
      icon: 'fa-xmark-circle',
      headingColor: '#D10E0E',
      headingText: 'What can’t I do?',
      bodyText: 'Access to the Financial Summary and Data Categorization pages for completed spreads is restricted. While new documents can be uploaded and spread, you will not be able to complete them until processing is complete.'
    },
    {
      icon: 'fa-question-circle',
      headingColor: '#464646',
      headingText: 'Questions?',
      bodyText: 'For questions or details regarding changes to this template, please contact your administrator.'
    },
  ];
  currentLoadingAnimationIconIndex: number = 0;
  private animationInterval: ReturnType<typeof setInterval>;
  loadingAnimationIconOptions: string[] = [
    'fa-hourglass-half',
    'fa-list-check',
    'fa-calendar-check',
    'fa-rocket'
  ];
  currentAnimationIcon: string = this.loadingAnimationIconOptions[this.currentLoadingAnimationIconIndex];

  constructor(
    private _crd: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.animationInterval = setInterval(this.changeAnimationIcon.bind(this), animationIconChangeIntervalInMS);
  }

  changeAnimationIcon() {
    if (this.currentLoadingAnimationIconIndex + 1 >= this.loadingAnimationIconOptions.length) {
      this.currentLoadingAnimationIconIndex = 0;
    } else {
      this.currentLoadingAnimationIconIndex++;
    }
    this.currentAnimationIcon = this.loadingAnimationIconOptions[this.currentLoadingAnimationIconIndex];
    this._crd.detectChanges();

  }

  ngOnDestroy() {
    clearInterval(this.animationInterval);
  }

}
